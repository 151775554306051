import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { Done, Refresh } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import useStyles from "./style";
import { checkIsMobile } from "../../Services/general";
import clsx from "clsx";
import HistoryView from "../HistoryView/HistoryView";
import statusMap from "../../Services/statuses";
import { HiOutlineDocumentText } from "react-icons/hi";
import ReportGenerator from "../ReportGenerator/ReportGenerator";

const Dashbaord = ({ user }) => {
  const classes = useStyles();
  const [allVisits, setAllVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [showMoney, setShowMoeny] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [wsObj, setWSObj] = useState(null);
  const [newVisitWS, setNewVisit] = useState(null);
  const [newToken, setToken] = useState("");
  const [filters, setFilters] = useState({ modality: "all", dep: "all" });
  const [mods, setMods] = useState([]);
  const [deps, setDeps] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    from: moment().startOf("month").unix(),
    to: moment().endOf("month").unix()
  });
  const [onlyWithLeft, setOnlyWithLeft] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [reportObj, setReportObj] = useState(null);

  console.log("reportobj   ", reportObj);

  // useEffect(() => {
  //     if (!wsObj) {
  //         return;
  //     }
  //     const temp = [...allVisits];

  //     const newVisits = [];
  //     for (let visit of temp) {
  //         if (visit._id === wsObj._id) {
  //             newVisits.push({ ...visit, ...wsObj });
  //         }
  //         else {
  //             newVisits.push(visit);
  //         }
  //     }

  //     setAllVisits(newVisits);
  // }, [wsObj]);

  useEffect(() => {
    if (!newVisitWS) {
      return;
    }
    const temp = [...allVisits, newVisitWS];

    setAllVisits(temp);
  }, [newVisitWS]);

  // useEffect(() => {
  //     const socket = new WebSocket('ws://http://ris.envision-solutions.com:9898')
  //     socket.addEventListener("message", (message) => {
  //         const obj = JSON.parse(message.data);
  //         if (obj.type === 'visit') {
  //             setWSObj(obj.data);
  //             return;
  //         }
  //         if (obj.type === 'newVisit') {
  //             setNewVisit(obj.data);
  //             return;
  //         }

  //     })

  //     return () => {
  //         socket.close();
  //     }
  // }, [])

  useEffect(() => {
    if (!user) {
      return;
    }
    Promise.allSettled([
      api.get(`/get-modalities`),
      api.get(`/getDepartments`)
    ]).then(([respMods, respDeps]) => {
      const modsData = respMods?.value?.data;
      const depsData = respDeps?.value?.data;
      setMods(modsData);
      setDeps(depsData);

      let selectedDep = "all";
      for (let dep of depsData) {
        if (!user?.isAdmin) {
          selectedDep = dep;
        }
      }
      setFilters({ ...filters, dep: selectedDep });
    });
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const start = dateFilter.from;
    const end = dateFilter.to;
    api
      .get(
        `/getAllVisits?from=${start}&to=${end}&user=${user._id}&allDebit=${onlyWithLeft}`
      )
      .then((resp) => {
        setAllVisits(resp.data);
      });
  }, [refresh, user, dateFilter, onlyWithLeft]);

  useEffect(() => {
    if (!allVisits.length) {
      setFilteredVisits([]);
      return;
    }

    const filtered = allVisits.filter(
      (visit) =>
        (filters.modality === "all" ||
          filters.modality === visit?.reqExams[0]?.modality) &&
        (filters.dep === "all" ||
          filters.dep === visit?.department ||
          !visit.department) &&
        (!searchVal
          ? true
          : visit.patientId.idNum.indexOf(searchVal) > -1 ||
            visit.patientId.name
              .toLowerCase()
              .indexOf(searchVal.toLowerCase()) > -1)
    );
    const grouped = _.groupBy(filtered, (a) =>
      moment.unix(a.dateTime).format("DD-MM")
    );
    const rows = [];

    const sortedDays = _.sortBy(Object.keys(grouped));

    for (let dayStr of sortedDays) {
      const day = _.sortBy(grouped[dayStr], "dateTime", "asc");
      rows.push(
        <TableRow
          className={clsx(classes.tr, isMobile && classes.mobileView)}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          style={{
            backgroundColor: "#f565e4",
            "& *": {
              fontWeight: "bold !important"
            }
          }}
        >
          <TableCell />
          <TableCell />
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            {moment.unix(day[0].dateTime).format("dddd")}
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            {moment.unix(day[0].dateTime).format("DD-MM-yyyy")}
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      );
      rows.push(
        <TableRow
          style={{
            backgroundColor: "#919191",
            fontWeight: "bold"
          }}
          className={clsx(classes.tr, isMobile && classes.mobileView)}
        >
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            #
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            ID
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Name
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Exams
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Dr
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Price
          </TableCell>

          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Notes
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Left
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Time
          </TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          ></TableCell>
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            Actions
          </TableCell>
        </TableRow>
      );
      const summary = { total: 0, left: 0 };
      const dayRows = day.map((visit, index) => {
        let exams = [...visit?.reqExams];
        if (visit.contrast) {
          exams.push({ name: "with contrast" });
        }
        if (visit.sedation) {
          exams.push({ name: "with sedation" });
        }
        summary.total = summary.total + visit.payment.resp;
        summary.left = summary.left + visit.debit;

        return (
          <TableRow
            className={clsx(classes.tr, isMobile && classes.mobileView)}
            style={{
              backgroundColor:
                statusMap[visit?.status || "started"].color || "#fff"
            }}
            key={visit._id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            onDoubleClick={() => setSelectedVisit(visit)}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{visit.patientId.idNum}</TableCell>
            <TableCell scope="row">{visit.patientId.name}</TableCell>
            <TableCell>{`${exams.map((ex) => ex.name)?.join(", ")} (${
              exams[0]?.modality
            })`}</TableCell>
            <TableCell>{visit.refDoctor.nameEn}</TableCell>
            <TableCell>{visit.payment["resp"]}</TableCell>

            <TableCell
              style={{ backgroundColor: visit?.paymentType?.color || "#fff" }}
            >
              <div style={{ fontSize: isMobile ? "11px" : "20px" }}>
                {visit.paymentType.Name !== "Self" && (
                  <Typography
                    style={{ fontSize: isMobile ? "11px" : "20px" }}
                  >{`${visit.paymentType.Name} covered ${visit.insuranceCoverageAmount}`}</Typography>
                )}
                <Typography style={{ fontSize: isMobile ? "11px" : "20px" }}>
                  {visit.notes}
                </Typography>
              </div>
            </TableCell>
            <TableCell
              style={{ backgroundColor: visit.debit > 0 ? "#ea9899" : "unset" }}
            >
              {visit.debit}
            </TableCell>
            <TableCell>{moment.unix(visit.dateTime).format("HH:mm")}</TableCell>
            <TableCell>{visit.department?.charAt(0)}</TableCell>
            <TableCell>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconButton size="small" onClick={() => setReportObj(visit)}>
                  <HiOutlineDocumentText />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => updateStatus(visit._id, visit.status, "read")}
                >
                  <Done style={{ color: "blue" }} />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        );
      });
      rows.push(...dayRows);
      rows.push(
        <TableRow
          className={clsx(classes.tr, isMobile && classes.mobileView)}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          style={{
            backgroundColor: "#fffd59"
          }}
        >
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />

          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            {summary.total}
          </TableCell>

          <TableCell />
          <TableCell
            style={{
              fontWeight: "bold"
            }}
          >
            {summary.left}
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      );
    }

    setFilteredVisits(rows);
  }, [allVisits, filters, mods, deps, searchVal]);

  const updateList = () => {
    setRefresh(refresh + 1);
  };

  const closeHistory = () => {
    setRefresh(refresh + 1);
    setSelectedVisit(null);
  };

  const updateStatus = (visitId, currentStatus, status) => {
    if (status === currentStatus) {
      return;
    }
    api
      .post(`/update-visit`, {
        id: visitId,
        data: { status }
      })
      .then(() => {
        setRefresh(refresh + 1);
      });
  };

  const isMobile = checkIsMobile();
  return (
    <div
      style={{
        padding: "20px",
        height: "calc(100% - 64px)",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {!user?.isAdmin ? (
        <>
          <Typography variant="h2">No user! Please contact IT</Typography>
          <TextField
            valu={newToken}
            onChange={(e) => setToken(e.target.value)}
            label="token"
          />
          <Button
            onClick={() => {
              localStorage.setItem("u_id", newToken);
              window.location.reload();
            }}
          >
            add token
          </Button>
        </>
      ) : (
        <>
          <div className={classes.controls}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              {!isMobile && (
                <TextField
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                  label="Search"
                  variant="outlined"
                />
              )}
              {!isMobile && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyWithLeft}
                      onChange={(e) => setOnlyWithLeft(e.target.checked)}
                    />
                  }
                  label={"Show only with left"}
                  labelPlacement="end"
                />
              )}
            </div>

            <div
              style={{
                display: "flex",
                width: "49%",
                justifyContent: "center"
              }}
            >
              <DatePicker
                views={["year", "month"]}
                openTo="month"
                start
                label="Year and Month"
                value={moment.unix(dateFilter?.from)}
                onChange={(val) =>
                  setDateFilter({
                    from: moment(val).startOf("month").unix(),
                    to: moment(val).endOf("month").unix()
                  })
                }
              />
              <IconButton onClick={() => setRefresh(refresh + 1)}>
                <Refresh />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                width: "53%"
              }}
            >
              {isMobile ? (
                <div className={classes.selectCont}>
                  <Typography>Modality: </Typography>
                  <Select
                    value={filters.modality}
                    onChange={(event) =>
                      setFilters({ ...filters, modality: event.target.value })
                    }
                  >
                    <MenuItem value="all">All</MenuItem>
                    {mods?.map((mod) => {
                      return (
                        <MenuItem key={`${mod}-list-item-option`} value={mod}>
                          {mod}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              ) : (
                <RadioGroup
                  value={filters?.modality}
                  onChange={(event) =>
                    setFilters({ ...filters, modality: event.target.value })
                  }
                  className={classes.radioGroup}
                  style={{
                    marginBottom: "10px"
                  }}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All Modalities"
                    labelPlacement="start"
                  />
                  {mods?.map((mod) => {
                    return (
                      <FormControlLabel
                        key={`${mod}-chkbox-option`}
                        control={<Radio />}
                        label={mod}
                        value={mod}
                        labelPlacement="start"
                      />
                    );
                  })}
                </RadioGroup>
              )}

              {isMobile ? (
                <div className={classes.selectCont}>
                  <Typography>Center: </Typography>
                  <Select
                    value={filters.dep}
                    onChange={(event) =>
                      setFilters({ ...filters, dep: event.target.value })
                    }
                  >
                    <MenuItem value="all">All</MenuItem>
                    {deps?.map((dep) => {
                      return (
                        <MenuItem key={`${dep}-list-item-option`} value={dep}>
                          {dep}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              ) : (
                <RadioGroup
                  defaultValue="all"
                  value={filters?.dep}
                  onChange={(event) =>
                    setFilters({ ...filters, dep: event.target.value })
                  }
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All Centers"
                    labelPlacement="start"
                  />
                  {deps?.map((dep) => {
                    return (
                      <FormControlLabel
                        key={`${dep}-chkbox-option`}
                        control={<Radio />}
                        label={dep}
                        value={dep}
                        labelPlacement="start"
                      />
                    );
                  })}
                </RadioGroup>
              )}
            </div>
          </div>

          <div
            style={{ width: "50px", height: "20px", cursor: "pointer" }}
            onClick={() => setShowMoeny(!showMoney)}
          />
          <TableContainer
            component={Paper}
            style={{
              flex: 1,
              "& *": {
                fontSize: "11px !important"
              }
            }}
          >
            <Table sx={{ minWidth: 650 }} stickyHeader size="small">
              <TableBody>
                {filteredVisits.map((visitRow) => visitRow)}
              </TableBody>
            </Table>
          </TableContainer>
          {!isMobile && selectedVisit && (
            <HistoryView
              isOpen={selectedVisit}
              closeDialog={closeHistory}
              visit={selectedVisit}
              user={user}
              updateList={updateList}
              setRefresh={setRefresh}
            />
          )}
        </>
      )}
      {reportObj && (
        <ReportGenerator
          id={reportObj._id}
          name={reportObj?.patientId?.name}
          onClose={() => setReportObj(null)}
        />
      )}
    </div>
  );
};

export default Dashbaord;
