import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: "nowrap",
        marginTop: '40px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        border: "1px solid #cecece",
        padding: '10px',
        borderRadius: '5px'
    },
    tr: {
        height: '10px',
        "& td": {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    topSearch: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    pdfCont: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '90%'
    },
    moreActionsCont: {
        height: '160px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    actionGroup: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around'
    }
}));

export default useStyles;
