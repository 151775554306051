import React, { useEffect, useState } from 'react';
import moment from "moment"
import useStyles from "./style"
import api from "../../api";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';


const PaymentEdit = ({ isOpen, closeDialog, visit, user, editVisit }) => {

    const [paymentData, setPaymentData] = useState({})
    const classes = useStyles();

    useEffect(() => {
        setPaymentData({
            payment: visit.payment,
            debit: visit.debit,
            insuranceCoverageAmount: visit.insuranceCoverageAmount,
            contrastBottleNum: visit.contrastBottleNum,
            notes: visit.notes
        })
    }, [visit])

    const updateVisit = () => {

        let visitInfo = {};
        if (role === "tech") {
            const { notes, ...rest } = paymentData;
            visitInfo = { ...rest };
        }
        else {
            visitInfo = { ...paymentData };
        }
        const data = {
            id: visit._id,
            data: visitInfo
        };



        api.post(`/update-visit`, data)
            .finally(() => closeDialog())

    }

    const exams = visit?.reqExams ? [...visit?.reqExams] : [];
    if (visit?.contrast) {
        exams.push({ name: 'with contrast' })
    }
    if (visit?.sedation) {
        exams.push({ name: 'with sedation' })
    }

    const role = user?.role;

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div>
                    <span style={{ color: '#fff' }}>{visit?._id}</span>
                    <DialogTitle id="alert-dialog-title">
                        {"Upate Patient Visit"}
                    </DialogTitle>
                </div>
                <IconButton onClick={() => editVisit(visit)}>
                    <Edit />
                </IconButton>
            </div>
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.patientSection} style={{ marginBottom: '20px', height: '30%' }}>
                        <div className={classes.patientInfo} style={{ height: '100%' }}>

                            <div className={classes.fieldContainer}>
                                <Typography>ID:</Typography>
                                <Typography>{visit?.patientId?.idNum}</Typography>
                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Patient Name:</Typography>
                                <Typography>{visit?.patientId?.name}</Typography>

                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Phone:</Typography>
                                <Typography>{visit?.patientId?.phone}</Typography>
                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Date of Birth:</Typography>
                                <Typography>{visit?.patientId?.dob && moment(visit?.patientId?.dob).format("YYYY-MM-DD")}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className={classes.patientInfo} style={{ marginBottom: '20px' }}>

                        <div className={classes.fieldContainer}>
                            <Typography>Modality:</Typography>
                            <Typography>{visit?.reqExams?.length && visit?.reqExams[0]?.modality}</Typography>
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Visit Time:</Typography>
                            <Typography>{visit?.dateTime && moment.unix(visit?.dateTime).format('YYYY-MM-DD HH:mm')}</Typography>
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Exams:</Typography>
                            <Typography>{exams?.map((e) => e.name)?.join(', ') || ""}</Typography>
                        </div>

                        <div className={classes.fieldContainer} />
                        <div className={classes.fieldContainer}>
                            <Typography>Amount Paied:</Typography>
                            <TextField
                                value={(paymentData?.payment && paymentData?.payment[role]) || ""}
                                onChange={(e) => setPaymentData({ ...paymentData, payment: { ...paymentData.payment, [role]: e.target.value } })}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                variant="outlined"
                                type="number"
                            />
                        </div>



                        <div className={classes.fieldContainer}>
                            <Typography>Amount Left:</Typography>
                            <TextField
                                value={paymentData?.debit}
                                onChange={(e) => setPaymentData({ ...paymentData, debit: e.target.value })}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                variant="outlined"
                                type="text"
                                inputProps={{
                                    pattern: "[0-9]*"
                                }}
                            />
                        </div>

                        {visit?.paymentType?.Name !== 'Self' &&
                            <div className={classes.fieldContainer} style={{ justifyContent: 'flex-start' }}>
                                <Typography>Insurance Coverage:</Typography>
                                <TextField
                                    value={paymentData?.insuranceCoverageAmount || ""}
                                    onChange={(e) => setPaymentData({ ...paymentData, insuranceCoverageAmount: e.target.value })}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    type="number"
                                />
                            </div>
                        }

                        <div className={classes.fieldContainer}>
                            <Typography>Notes:</Typography>
                            <TextField
                                value={paymentData?.notes || ""}
                                multiline
                                onChange={(e) => setPaymentData({ ...paymentData, notes: e.target.value })}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                rows={2}
                                variant="outlined"
                            />
                        </div>

                        {visit.contrast && (role === 'tech' || user?.isAdmin) &&
                            <div className={classes.fieldContainer} style={{ justifyContent: 'flex-start' }}>
                                <Typography>contrast bottle number:</Typography>
                                <TextField
                                    value={paymentData.contrastBottleNum || ""}
                                    onChange={(e) => setPaymentData({ ...paymentData, contrastBottleNum: e.target.value })}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    type="number"
                                />
                            </div>
                        }
                    </div>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={updateVisit}
                    >
                        Update
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PaymentEdit;