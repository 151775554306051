import axios from "axios";

const protocol = window.location.protocol; // will be 'http:' or 'https:'
const api = axios.create({
  baseURL: `${protocol}//api.envision-solutions.com`
  // "http://localhost:3001"
  // "http://api.envision-solutions.org"
  //"http://localhost:3000"
  //"http://ris.envision-solutions.com:3000"
  //"http://3.73.19.59:3000"
});

export default api;
