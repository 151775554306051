import { Box, FormControlLabel, Icon, IconButton, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import useStyles from "./style";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";




const RefSoctorStats = ({ user, setIsLoading, apiFilters, filters, refresh }) => {
    const classes = useStyles();
    const [refDoctorsStats, setRefDoctorsStats] = useState([]);
    const [monthView, setMonthView] = useState("table");


    useEffect(() => {
        if (!user?.isAdmin) {
            return;
        }

        setIsLoading(true);
        api.post(`/getStatsForRefDocs`, {
            ...apiFilters,
            selectedModality: filters.modality,
            selectedDepartment: filters.dep
        })
            .then((resp) => {
                setRefDoctorsStats(resp.data);
            })
            .finally(() => setIsLoading(false));

    }, [refresh, user]);


    const allDrsArr = refDoctorsStats.map((a) => {
        const { month: rv, ...rest } = a;
        return Object.keys(rest);
    });

    const drsSet = new Set(allDrsArr.flat(1));


    return (

        <>
            <RadioGroup
                defaultValue="table"
                value={monthView}
                onChange={(event) => setMonthView(event.target.value)}
                className={classes.radioGroup}
            >

                <FormControlLabel value="table" control={<Radio />} label="Table view" labelPlacement="start" />
                <FormControlLabel value="graphs" control={<Radio />} label="Graphs view" labelPlacement="start" />

            </RadioGroup>
            <div style={{ display: 'flex', flex: 1, flexDirection: "column", width: '100%', height: '100%' }}>
                {monthView === 'table' ?
                    (<div>
                        {
                            !!refDoctorsStats?.length && refDoctorsStats.map((month) => {
                                const { month: selectedMonth, ...doctors } = month

                                if (_.isEmpty(doctors)) {
                                    return null;
                                }

                                const sortedDoctors = _.orderBy(Object.keys(doctors), (d) => doctors[d], "desc")
                                return (
                                    <TableContainer component={Paper} key={`month-${month.month}-table`} style={{ marginBottom: '30px', flex: 1 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader size="small">
                                            <TableBody>
                                                <TableRow style={{ backgroundColor: "rgb(245, 101, 228)" }}>
                                                    <TableCell style={{ width: '50%' }}>
                                                        Month
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(selectedMonth).format("MMMM-YYYY")}
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    sortedDoctors?.map((dr, idx) => {


                                                        return (
                                                            <TableRow key={`${dr}-${idx}`} style={{ backgroundColor: idx % 2 ? "rgba(0,0,0,0.1)" : "#fff" }}>
                                                                <TableCell>
                                                                    {dr}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {doctors[dr]}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            })
                        }
                    </div>) :
                    <>
                        {
                            !!refDoctorsStats?.length &&
                            <ResponsiveBar
                                data={refDoctorsStats}
                                keys={Array.from(drsSet)}
                                indexBy="month"
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.3}
                                // valueScale={{ type: 'linear' }}
                                // indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'nivo' }}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'Self'
                                        },
                                        id: 'lines'
                                    },
                                    // {
                                    //     match: {
                                    //         id: 'sandwich'
                                    //     },
                                    //     id: 'lines'
                                    // }
                                ]}
                                // borderColor={{
                                //     from: 'color',
                                //     modifiers: [
                                //         [
                                //             'darker',
                                //             1.6
                                //         ]
                                //     ]
                                // }}
                                axisTop={null}
                                axisRight={null}
                            // axisBottom={{
                            //     tickSize: 5,
                            //     tickPadding: 5,
                            //     tickRotation: 0,
                            //     legend: 'month',
                            //     legendPosition: 'middle',
                            //     legendOffset: 32
                            // }}
                            // axisLeft={{
                            //     tickSize: 5,
                            //     tickPadding: 5,
                            //     tickRotation: 0,
                            //     legend: 'count',
                            //     legendPosition: 'middle',
                            //     legendOffset: -40
                            // }}
                            // labelSkipWidth={12}
                            // labelSkipHeight={12}
                            // labelTextColor={{
                            //     from: 'color',
                            //     modifiers: [
                            //         [
                            //             'darker',
                            //             1.6
                            //         ]
                            //     ]
                            // }}
                            // legends={[
                            //     {
                            //         dataFrom: 'keys',
                            //         anchor: 'bottom-right',
                            //         direction: 'column',
                            //         justify: false,
                            //         translateX: 120,
                            //         translateY: 0,
                            //         itemsSpacing: 2,
                            //         itemWidth: 100,
                            //         itemHeight: 20,
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 0.85,
                            //         symbolSize: 20,
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemOpacity: 1
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                            // role="application"
                            // ariaLabel="Nivo bar chart demo"
                            // barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                            />

                        }
                    </>
                }
            </div>
        </>


    )
}

export default RefSoctorStats;