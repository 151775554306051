import { Box, Checkbox, CircularProgress, FormControlLabel, IconButton, Radio, RadioGroup, Tab, Tabs, TextField } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import useStyles from "./style";
import { TabContext } from "@material-ui/lab";
import RefSoctorStats from "./RefDoctorStats";
import VisitsOfDayTimes from "./VisitsDuringTheDay";



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ height: '100%', padding: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}



const StatsPage = ({ user }) => {
    const classes = useStyles();
    const [type, setType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [mods, setMods] = useState([]);
    const [filters, setFilters] = useState({ dep: "all", modality: 'all' });
    const [deps, setDeps] = useState([]);
    const [refDoctorsStats, setRefDoctorsStats] = useState([]);
    const [apiFilters, setApiFilters] = useState({
        moreThan: 10,
        withFuture: false,
        withSelf: true
    });

    useEffect(() => {
        if (!user) {
            return;
        }
        Promise.allSettled([api.get(`/get-modalities`), api.get(`/getDepartments`), api.get(`/get-modalities`)])
            .then(([respMods, respDeps, modalitiesResp]) => {
                const modsData = respMods?.value?.data;
                const depsData = respDeps?.value?.data;
                const modalitiesData = modalitiesResp?.value?.data;
                setMods(modsData);
                setDeps(depsData);
            })
    }, [user]);

    useEffect(() => {
        if (!user?.isAdmin) {
            return;
        }

        setIsLoading(true);
        api.post(`/getStatsForRefDocs`, {
            ...apiFilters,
            selectedModality: filters.modality,
            selectedDepartment: filters.dep
        })
            .then((resp) => {
                setRefDoctorsStats(resp.data);
            })
            .finally(() => setIsLoading(false));

    }, [refresh, user]);


    return (

        <div style={{ padding: '20px', height: "calc(100% - 64px)", boxSizing: 'border-box', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {isLoading && <div
                style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 99999
                }}
            >
                <CircularProgress size={130} thickness={2} />

            </div>}

            {
                user?.isAdmin &&
                <TabContext value={type}>
                    <Tabs value={type} centered onChange={(_, newVal) => setType(newVal)} variant="fullWidth" indicatorColor="secondary">
                        <Tab label="Referal Doctors" {...a11yProps(0)} style={{ width: "50%" }} />
                        {/* <Tab label="Visits During the day" {...a11yProps(1)} style={{ width: "50%" }} /> */}
                    </Tabs>
                    <>
                        <div className={classes.controls}>


                            <>
                                <RadioGroup
                                    defaultValue="all"
                                    value={filters?.dep}
                                    onChange={(event) => setFilters({ ...filters, dep: event.target.value })}
                                    className={classes.radioGroup}
                                >

                                    <FormControlLabel value="all" control={<Radio />} label="All Centers" labelPlacement="start" />
                                    {
                                        deps?.map((dep) => {
                                            return (
                                                <FormControlLabel
                                                    key={`${dep}-chkbox-option`}
                                                    control={<Radio />}
                                                    label={dep}
                                                    value={dep}
                                                    labelPlacement="start"
                                                />
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </>

                            <RadioGroup
                                value={filters?.modality}
                                onChange={(event) => setFilters({ ...filters, modality: event.target.value })}
                                className={classes.radioGroup}
                                style={{
                                    marginBottom: '10px'
                                }}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All Modalities" labelPlacement="start" />
                                {
                                    mods?.map((mod) => {
                                        return (
                                            <FormControlLabel
                                                key={`${mod}-chkbox-option`}
                                                control={<Radio />}
                                                label={mod}
                                                value={mod}
                                                labelPlacement="start"
                                            />
                                        )
                                    })
                                }
                            </RadioGroup>

                            <TextField
                                value={apiFilters?.moreThan}
                                onChange={(e) => setApiFilters({ ...apiFilters, moreThan: +e.target.value })}
                                type="number"
                                variant="outlined"
                            />

                            <FormControlLabel
                                control={<Checkbox />}
                                label={"Include patient without Ref Doctor"}
                                value={apiFilters.withSelf}
                                checked={apiFilters.withSelf}
                                labelPlacement="start"
                                onChange={() => setApiFilters((old) => ({ ...apiFilters, withSelf: !old.withSelf }))}
                            />

                            <FormControlLabel
                                control={<Checkbox />}
                                label={"Include future dates"}
                                value={apiFilters.withFuture}
                                checked={apiFilters.withFuture}
                                labelPlacement="start"
                                onChange={() => setApiFilters((old) => ({ ...apiFilters, withFuture: !old.withFuture }))}
                            />
                            <IconButton onClick={() => setRefresh((old) => old + 1)}>
                                <Check />
                            </IconButton>
                        </div>

                        <TabPanel value={type} index={0} style={{ height: "calc(100% - 190px)" }}>
                            <RefSoctorStats
                                user={user}
                                refresh={refresh}
                                setIsLoading={setIsLoading}
                                apiFilters={apiFilters}
                                filters={filters}
                            />

                        </TabPanel>
                        {/* <TabPanel value={type} index={1} style={{ height: "calc(100% - 190px)" }}>

                            <VisitsOfDayTimes
                                user={user}
                                refresh={refresh}
                                setIsLoading={setIsLoading}
                                apiFilters={apiFilters}
                                filters={filters}
                            />
                        </TabPanel> */}

                    </>


                </TabContext>
            }
        </div >


    )
}

export default StatsPage;