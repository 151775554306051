import React, { useEffect, useState } from 'react';
import useStyles from "./style"
import api from "../../api";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';


const EditHistory = ({ isOpen, closeDialog, visitId }) => {


    const classes = useStyles();
    const [history, setHistory] = useState({})
    const [respStaff, setRespStaff] = useState([]);
    const [techStaff, setTechStaff] = useState([]);

    useEffect(() => {
        api.get(`/get-staff`)
            .then(({ data }) => {
                const resp = [];
                const tech = [];

                for (let s of data) {
                    if (!s.isActive) {
                        continue;
                    }
                    if (s.title === "Receptionist") {
                        resp.push(<MenuItem value={s._id}>{s.name}</MenuItem>)
                        continue;
                    }
                    if (s.title === "Tech") {
                        tech.push(<MenuItem value={s._id}>{s.name}</MenuItem>)
                        continue;
                    }
                }
                setTechStaff(tech);
                setRespStaff(resp);
            })
    }, []);

    useEffect(() => {
        if (!visitId) {
            return;
        }
        api(`/getVisit?id=${visitId}`)
            .then(({ data }) => {
                const obj = {};
                obj.priorExams = data.priorExams || {};
                obj.trauma = data.trauma || [];
                obj.surgery = data.surgery || [];
                obj.weight = data.weight || 0;
                obj.genHistory = data.genHistory || "";

                setHistory(obj);
            })
    }, [visitId])



    const save = () => {
        api.post(`/update-visit`, { id: visitId, data: history })
            .finally(() => closeDialog())
    }

    const updateTrauma = (e, index) => {
        const traumaTemp = history.trauma;
        traumaTemp[index] = e.target.value;
        setHistory({ ...history, trauma: traumaTemp });
    }

    const updateSurgery = (e, index, field) => {
        const surgTemp = history.surgery;
        surgTemp[index][field] = e.target.value;
        setHistory({ ...history, surgery: surgTemp });
    }

    const updatePrior = (e, index, field) => {
        const surgTemp = history.surgery;
        surgTemp[index][field] = e.target.value;
        setHistory({ ...history, surgery: surgTemp });
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                <DialogTitle>
                    {"Pateint History"}
                </DialogTitle>
            </div>
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.patientInfo}>
                        <div className={classes.fieldContainer}>
                            <Typography>History:</Typography>
                            <TextField
                                value={history?.genHistory || ""}
                                onChange={(e) => setHistory({ ...history, genHistory: e.target.value })}
                                className={classes.patientField}
                                classes={{
                                    root: classes.fieldRoot,
                                    multiline: classes.fieldRoot
                                }}
                                InputProps={{
                                    classes: {
                                        root: classes.fieldRoot
                                    }
                                }}
                                variant="outlined"
                                multiline
                                minRows={6}
                            />
                        </div>
                        <div className={classes.fieldContainer}>
                            <Typography>Weight:</Typography>
                            <TextField
                                value={history?.weight || ""}
                                onChange={(e) => setHistory({ ...history, weight: e.target.value })}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                variant="outlined"
                                type={'number'}
                            />
                        </div>
                        <div className={classes.fieldContainer} />
                        <div className={classes.fieldContainer}>
                            <Typography>Technician:</Typography>
                            <Select
                                variant="outlined"
                                className={classes.patientField}
                                value={history?.historyCollector || ""}
                                onChange={(e) => setHistory({ ...history, historyCollector: e.target.value })}
                                displayEmpty
                            >
                                <MenuItem disabled value="">Select Tech</MenuItem>
                                {techStaff}
                            </Select>
                        </div>
                    </div>
                    <div className={classes.patientInfo}
                        style={{
                            flexDirection: 'column'
                        }}>
                        <div className={classes.sectionHeader}>
                            <Typography>Traumas Section:</Typography>
                            <IconButton
                                onClick={() => setHistory({ ...history, trauma: [...(history.trauma || []), ""] })}
                                className={classes.addBtn}
                            >
                                <Add style={{ color: '#00f' }} />
                            </IconButton>
                        </div>

                        <div className={classes.sectionRepeateContent}>

                            {
                                history?.trauma?.map((tru, index) => {
                                    return (
                                        <div className={classes.fieldContainer} key={`tru-${index}`}>
                                            <Typography>Trauma Summary:</Typography>
                                            <TextField
                                                value={tru || ""}
                                                onChange={(e) => updateTrauma(e, index)}
                                                className={classes.patientField}
                                                classes={{ root: classes.fieldRoot }}
                                                variant="outlined"
                                                multiline
                                                raws={3}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={classes.patientInfo} style={{
                        flexDirection: 'column'
                    }}>
                        <div className={classes.sectionHeader}>
                            <Typography>Surgeries: </Typography>
                            <IconButton
                                onClick={() => setHistory({ ...history, surgery: [...(history.surgery || []), { summary: "", doctor: "" }] })} className={classes.addBtn}
                            >
                                <Add style={{ color: '#00f' }} />
                            </IconButton>
                        </div>
                        <div className={classes.sectionRepeateContent}>

                            {
                                history?.surgery?.map((surg, index) => {
                                    return (
                                        <>
                                            <div className={classes.fieldContainer} key={`surg-${index}`}>
                                                <Typography>Surgery Summary:</Typography>
                                                <TextField
                                                    value={surg.summary || ""}
                                                    onChange={(e) => updateSurgery(e, index, 'summary')}
                                                    className={classes.patientField}
                                                    classes={{ root: classes.fieldRoot }}
                                                    variant="outlined"
                                                    multiline
                                                    raws={3}
                                                />
                                            </div>
                                            <div className={classes.fieldContainer} key={`surg-${index}`}>
                                                <Typography>Surgery doctor:</Typography>
                                                <TextField
                                                    value={surg.doctor || ""}
                                                    onChange={(e) => updateSurgery(e, index, 'doctor')}
                                                    className={classes.patientField}
                                                    classes={{ root: classes.fieldRoot }}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={classes.patientInfo} style={{
                        flexDirection: 'column'
                    }}>
                        <div className={classes.sectionHeader}>
                            <Typography>Prior Cases:</Typography>
                        </div>
                        <div className={classes.sectionRepeateContent}>

                            {
                                <>
                                    <div className={classes.fieldContainer}>
                                        <Typography>Media:</Typography>
                                        <Select
                                            variant="outlined"
                                            className={classes.patientField}
                                            value={history?.priorExams?.media || ""}
                                            onChange={(e) => setHistory({ ...history, priorExams: { ...history?.priorExams, media: e.target.value } })}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">Select Media</MenuItem>
                                            <MenuItem value="CD">CD</MenuItem>
                                            <MenuItem value="link">Link</MenuItem>
                                            <MenuItem value="internal">Internal</MenuItem>

                                        </Select>
                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <Typography>Modality:</Typography>
                                        <Select
                                            className={classes.patientField}
                                            variant="outlined"
                                            value={history?.priorExams?.modality || ""}
                                            onChange={(e) => setHistory({ ...history, priorExams: { ...history?.priorExams, modality: e.target.value } })}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">Select Modality</MenuItem>
                                            <MenuItem value="CT">CT</MenuItem>
                                            <MenuItem value="MRI">MRI</MenuItem>
                                        </Select>
                                    </div>

                                    <div className={classes.fieldContainer}>
                                        <Typography>Date:</Typography>
                                        <TextField
                                            value={history?.priorExams?.date || ""}
                                            onChange={(e) => setHistory({ ...history, priorExams: { ...history?.priorExams, date: e.target.value } })}
                                            className={classes.patientField}
                                            classes={{ root: classes.fieldRoot }}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <Typography>Receptionist:</Typography>
                                        <Select
                                            variant="outlined"
                                            className={classes.patientField}
                                            value={history?.priorExams?.resp || ""}
                                            onChange={(e) => setHistory({ ...history, priorExams: { ...history?.priorExams, resp: e.target.value } })}
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">Select Resp</MenuItem>
                                            {respStaff}
                                        </Select>
                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <Typography>Notes:</Typography>
                                        <TextField
                                            value={history?.priorExams?.notes || ""}
                                            onChange={(e) => setHistory({ ...history, priorExams: { ...history?.priorExams, notes: e.target.value } })}
                                            className={classes.patientField}
                                            classes={{ root: classes.fieldRoot }}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.fieldContainer} />

                                </>

                            }
                        </div>
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                    >
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog >
    )
}

export default EditHistory;