import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Dashbaord, DashbaordMonth } from './Screens';
import { useEffect, useState } from 'react';
import api from "./api"
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SummaryPage from './Screens/Summary/Summary';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import StatsPage from './Screens/Stats/Stats';
import ReportGenerator from './Screens/ReportGenerator/ReportGenerator';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar position="static">
      <Toolbar style={{ height: '64px', backgroundColor: '#000' }}>
        <img src={"./logo.PNG"} alt="" style={{ height: '100%' }} />
        <Button
          onClick={() => navigate("/dr")}
          variant='text'
          style={{ color: '#fff', height: '100%', backgroundColor: location.pathname === '/dr' ? "rgb(183 35 35)" : "transparent" }}>
          Patients List
        </Button>
        <Button
          onClick={() => navigate("/summary")}
          variant='text'
          style={{ color: '#fff', height: '100%', backgroundColor: location.pathname === '/summary' ? "rgb(183 35 35)" : "transparent" }}>
          Summary
        </Button>
        <Button
          onClick={() => navigate("/stats")}
          variant='text'
          style={{ color: '#fff', height: '100%', backgroundColor: location.pathname === '/stats' ? "rgb(183 35 35)" : "transparent" }}>
          Stats
        </Button>
      </Toolbar>
    </AppBar>
  )
}
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userId = localStorage.getItem('u_id');
    if (!userId) {
      return;
    }

    api.get(`/get-user?userId=${userId}`)
      .then(({ data }) => {
        setUser(data);
      })
  }, []);

  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          {user?.isAdmin && <NavBar />}
          <Routes>
            <Route path="/" element={<Dashbaord user={user} />} />
            <Route path="/dr" element={<DashbaordMonth user={user} />} />
            <Route path="/summary" element={<SummaryPage user={user} />} />
            <Route path="/stats" element={<StatsPage user={user} />} />
            <Route path="/reportEditor/:id?" element={<ReportGenerator user={user} />} />
          </Routes>
        </Router>
      </MuiPickersUtilsProvider>



    </div>
  );
}

export default App;
