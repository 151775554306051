import React, { useEffect, useState } from "react";
import api from "../../api";
import ClockLoader from "react-spinners/ClockLoader";
import { AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 70px)'
    },
    documentEditor: {
        border: '1px solid var(--ck-color-base-border)',
        borderRadius: 'var(--ck-border-radius)',
        maxHeight: '700px',
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    documentEditorToolbar: {
        zIndex: 1,
        boxShadow: '0 0 5px hsla(0,0%,0%)'
    },

}));


const path = "https://hmic-files.s3.eu-central-1.amazonaws.com/templates/template1.docx";
const ReportGenerator = ({ id, onClose, name }) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [filePath, setFilePath] = useState(null)

    useEffect(() => {
        if (!id) {
            return;
        }

        setIsLoading(true);
        api.get(`/getVisit?id=${id}`)
            .then(async (visitResp) => {
                const visitData = visitResp.data;

                if (visitData?.docTempPath?.length) {
                    setFilePath(visitData?.docTempPath);
                    return;
                }

                const docPath = await api.get(`/createReport?templateURL=${path}&visitId=${id}`)
                setFilePath(docPath.data.url);
            })
            .finally(() => setIsLoading(false))
    }, [id])


    return (
        <Dialog fullScreen open={!!id} onClose={onClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <Close />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {`Report for ${name}`}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onClose}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.dialogContent}>
                {isLoading && <ClockLoader size={180} />}
                {!isLoading && !!filePath &&
                    <iframe
                        title="Google Doc"
                        src={`${filePath}?embedded=true`}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                }
            </div>
        </Dialog>

    )
}


export default ReportGenerator;