import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '25px',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: '30px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row'
    },
    tr: {
        height: '10px',
        "& td": {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    fullHeight: {
        height: '100%'
    }
}));

export default useStyles;
