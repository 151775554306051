import React, { useEffect, useState } from 'react';
import moment from "moment"
import useStyles from "./style"
import api from "../../api";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab/';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';

const filter = createFilterOptions();

const AddEditVisit = ({ isOpen, closeDialog, user, editVisitObj }) => {

    const [patient, setPatient] = useState({});
    const [visit, setVisit] = useState({
        refDoctor: { _id: '62f95d03d12f0b43d9ab9e68', nameEn: 'Self' },
        paymentType: "62f41facba3b2f61cca12171"
    });
    const [enablePat, setEnablePat] = useState(true);
    const [existPat, setExisting] = useState([]);
    const [examsOptions, setExamsOptions] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [allMods, setMods] = useState([]);
    const [allDoctors, setAllDoctors] = useState([]);
    const [modality, setModality] = useState("");
    const [errors, setErrors] = useState({});

    const classes = useStyles();

    useEffect(() => {
        if (!editVisitObj) {
            return;
        }
        setEnablePat(true);
        setPatient(editVisitObj?.patientId);
        setVisit({ ...editVisitObj, paymentType: editVisitObj?.paymentType._id, dateTime: moment.unix(editVisitObj?.dateTime) });
        setModality(editVisitObj?.reqExams[0]?.modality);
    }, [editVisitObj])

    useEffect(() => {
        Promise.all([api.get(`/get-modalities`), api.get(`/get-payment-types`), api.get(`/get-all-doctors`)])
            .then(([{ data: modsData }, { data: paymentData }, { data: doctorsData }]) => {
                setMods(modsData)
                setPaymentTypes(paymentData);
                setAllDoctors(doctorsData);
            })
    }, [])

    useEffect(() => {
        if (!modality) {
            return;
        }

        api.get(`/get-exams?modality=${modality}`)
            .then(({ data }) => {
                setExamsOptions(data);
            })
    }, [modality])

    const searchPat = (value) => {
        api.get(`/find-pat?id=${value}`)
            .then((resp) => {
                setExisting(resp.data.map((p) => {
                    return { _id: p._id.toString(), name: p.name, idNum: p.idNum, obj: p }
                }));
            })
    }

    const save = () => {
        const localErrors = {
            idNum: !patient.idNum,
            name: !patient.name,
            phone: !patient.phone,
            gender: !patient.gender,
            dob: !patient.dob,
            reqExams: !visit.reqExams?.length,
        };

        var phoneno = /^\d{10}$/;
        if (!patient.phone?.match(phoneno)) {
            localErrors.phone = true;
        }
        else {
            localErrors.phone = false;
        }

        setErrors({
            ...errors,
            ...localErrors
        });

        if (Object.values(localErrors).filter(e => e)?.length) {
            return;
        }

        const data = {
            patient,
            visit: {
                ...visit,
                dateTime: moment(visit.dateTime).unix(),
                department: user.department
            }
        };


        if (!!editVisitObj) {
            api.post(`/update`, data)
                .finally(() => closeDialog())
            return;
        }
        api.post(`/new`, data)
            .finally(() => closeDialog())
    }

    const clearForm = () => {
        setVisit({
            paymentType: "62f41facba3b2f61cca12171",
            refDoctor: { _id: '62f95d03d12f0b43d9ab9e68', nameEn: 'Self' }, contrast: false, sedation: false
        });
        setPatient({});
        setEnablePat(true);
        setModality("");
        setExamsOptions([]);
        setErrors({});
    }

    const updatePhone = (e) => {
        const value = e.target.value;
        setPatient({ ...patient, phone: value });
        var phoneno = /^\d{10}$/;
        if (value.match(phoneno)) {
            setErrors({ ...errors, phone: false });
            return;
        }
        setErrors({ ...errors, phone: true });
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                <DialogTitle>
                    {"New Patient Visit"}
                </DialogTitle>
                <Button
                    onClick={clearForm}
                >Clear form</Button>
            </div>
            <DialogContent>
                <div className={classes.container}>
                    <div className={classes.patientSection} style={{ marginBottom: '20px' }}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setPatient({
                                        idNum: newValue,
                                    });
                                    setEnablePat(true)
                                }
                                else {
                                    setPatient(newValue.obj);
                                    setEnablePat(true)
                                }
                            }}
                            selectOnFocus
                            onInputChange={(e) => searchPat(e?.target?.value)}
                            handleHomeEndKeys
                            options={existPat}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return `${option.name} - ${option.idNum}`;
                            }}
                            sx={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Search Patient" variant='outlined' />
                            )}
                        />

                        <div className={classes.patientInfo} style={{ minHeight: '70%' }}>

                            <div className={classes.fieldContainer}>
                                <Typography>ID:</Typography>
                                <TextField
                                    value={patient?.idNum || ""}
                                    onChange={(e) => setPatient({ ...patient, idNum: e.target.value })}
                                    disabled={!enablePat}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    error={errors.idNum}
                                />
                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Phone:</Typography>
                                <TextField
                                    value={patient?.phone || ""}
                                    onChange={(e) => updatePhone(e)}
                                    disabled={!enablePat}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    error={errors.phone}
                                />
                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Patient Name:</Typography>
                                <TextField
                                    value={patient?.name || ""}
                                    onChange={(e) => setPatient({ ...patient, name: e.target.value })}
                                    disabled={!enablePat}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    error={errors.name}
                                />
                            </div>

                            <div className={classes.fieldContainer}>
                                <Typography>Arabic Patient Name:</Typography>
                                <TextField
                                    value={patient?.nameAr || ""}
                                    onChange={(e) => setPatient({ ...patient, nameAr: e.target.value })}
                                    disabled={!enablePat}
                                    className={classes.patientField}
                                    classes={{ root: classes.fieldRoot }}
                                    variant="outlined"
                                    error={errors.name}
                                />
                            </div>



                            <div className={classes.fieldContainer}>
                                <div>
                                    <Typography>Date of Birth:</Typography>
                                </div>
                                <KeyboardDatePicker
                                    clearable
                                    value={patient?.dob}
                                    placeholder="10/10/2018"
                                    className={classes.patientField}
                                    onChange={(val) => setPatient({ ...patient, dob: val })}
                                    format="DD/MM/yyyy"
                                    variant='inline'
                                    inputVariant='outlined'
                                />
                            </div>

                            <div className={classes.fieldContainer}>
                                <div>
                                    <Typography>Gender:</Typography>
                                </div>
                                <Select
                                    value={patient?.gender || ""}
                                    onChange={(e) => setPatient({ ...patient, gender: e.target.value })}
                                    displayEmpty
                                    className={classes.patientField}
                                    variant="outlined"
                                    error={errors.gender}
                                >
                                    <MenuItem disabled value="">Select Gender</MenuItem>
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.patientInfo} style={{ marginBottom: '20px' }}>

                        <div className={classes.fieldContainer}>
                            <Typography>Modality:</Typography>
                            <Select
                                value={modality || ""}
                                onChange={(e) => {
                                    setModality(e.target.value);
                                    setVisit({ ...visit, reqExams: [] })
                                }}
                                input={<OutlinedInput />}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                displayEmpty
                            >
                                <MenuItem value={""} disabled>{"Please Select Modality"}</MenuItem>
                                {
                                    allMods.map((m) => (
                                        <MenuItem value={m} key={m}>{m}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Visit Time:</Typography>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                value={visit?.dateTime}
                                className={classes.patientField}
                                onChange={(val) => setVisit({ ...visit, dateTime: val })}
                                format="DD/MM/YYYY HH:mm"
                                inputVariant='outlined'
                            />
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Exams:</Typography>
                            <Autocomplete
                                multiple
                                options={examsOptions}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} variant="outlined" error={errors.reqExams} />}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                value={visit.reqExams || []}
                                onChange={(e, newVal) => setVisit({ ...visit, reqExams: newVal })}
                                disableClearable
                            />
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Referral Docotr:</Typography>
                            <Autocomplete
                                options={allDoctors}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            title: `Add "${params.inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return `${option.nameEn}`
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                value={visit.refDoctor}
                                onChange={(e, newVal) => {
                                    if (newVal && newVal.inputValue) {
                                        setVisit({ ...visit, refDoctor: newVal.inputValue, isNewDr: true });
                                        return;
                                    }
                                    setVisit({ ...visit, refDoctor: newVal, isNewDr: false });
                                }}
                                disableClearable
                            />
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Payment Type:</Typography>
                            <Select
                                value={visit.paymentType || ""}
                                displayEmpty
                                onChange={(e) => setVisit({ ...visit, paymentType: e.target.value })}
                                input={<OutlinedInput />}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                            >
                                <MenuItem value={""} disabled>{"Select a payment type"}</MenuItem>
                                {
                                    paymentTypes.map((p) => (
                                        <MenuItem value={p._id} key={p._id}>{p.Name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </div>

                        <div className={classes.fieldContainer}>
                            <Typography>Notes:</Typography>
                            <TextField
                                value={visit.notes || ""}
                                multiline
                                onChange={(e) => setVisit({ ...visit, notes: e.target.value })}
                                className={classes.patientField}
                                classes={{ root: classes.fieldRoot }}
                                minRows={2}
                                variant="outlined"
                            />
                        </div>

                        <div className={classes.fieldContainer} style={{ justifyContent: 'flex-start' }}>
                            <Typography>Exam procedures:</Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={visit?.contrast || false}
                                    onChange={(e) => setVisit({ ...visit, contrast: e.target.checked })}
                                />}
                                label="With Contrast"
                                labelPlacement="start"
                            />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={visit?.sedation || false}
                                    onChange={(e) => setVisit({ ...visit, sedation: e.target.checked })}
                                />}
                                label="With Sedation"
                                labelPlacement="start"
                            />
                        </div>
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                    >
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default AddEditVisit;