const arabicRegix = /[\u0600-\u06FF]/;

const modalitiesSorting = [
    { "MRI": 1 },
    { CT: 2 },
    { US: 3 },
    { XRAY: 4 },
    { Outpatient: 5 }
]

export {
    arabicRegix,
    modalitiesSorting
}