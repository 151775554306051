import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({

    dialogPaper: {
        maxWidth: '90% !important',
        maxHeight: '90% !important',
        height: '90%',
        width: '90%',
    },
    container: {
        display: 'flex',
        flex: 1,
        padding: '20px 60px',
        overflow: 'hidden'
    },
    historySection: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto'
    },
    viewSection: {
        borderLeft: '1px solid #000',
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'auto'
    },
    dataHistory: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid black',
        margin: '10px',
        padding: '20px',
        borderRadius: '6px',
        backgroundColor: '#f0f0f0'
    },
    fieldTitle: {
        fontWeight: 'bold',
        marginRight: '30px',
        width: '135px'
    },
    fieldCont: {
        display: 'flex',
        "& p": {
            fontSize: '16px'
        }
    },
    sectionName: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px',
        boxSizing: 'border-box',
        "& *": {
            fontWeight: 'bold',
            fontSize: '20px'
        }
    },
    pdfCont: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '90%'
    }
}));
export default useStyles;
