import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import { AttachFile, Check, Refresh, Visibility } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import AddEditVisit from "../AddEditVisit/AddEditVisit";
import PaymentEdit from "../AddEditVisit/PaymentEdit";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import useStyles from "./style";
import EditHistory from "../EditHistory/EditHistory";
import { getResourcePath } from "../../Services/general";
import statusMap from "../../Services/statuses";
import { pdfjs } from "react-pdf";
import HistoryView from "../HistoryView/HistoryView";
import { MdOpenInFull } from "react-icons/md";
import { FiCopy, FiServer } from "react-icons/fi";
import { TbCloudDataConnection } from "react-icons/tb";
import { arabicRegix } from "../../consts";
import axios from "axios";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const localUrl = `http://envision.envision-solutions.com:8042/osimis-viewer/app/index.html?study=`;
const cloudUrl = `http://orth.envision-solutions.com:8042/osimis-viewer/app/index.html?study=`;

const Dashbaord = ({ user }) => {
  const classes = useStyles();
  const [allVisits, setAllVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [openNew, setOpenNew] = useState(false);
  const [showMoney, setShowMoeny] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [selectedVisitShow, setSelectedVisitShow] = useState(null);
  const [editHistory, setEditHistory] = useState(null);
  const [visitToUpdate, setVisitToUpdate] = useState(null);
  const [newToken, setToken] = useState("");
  const [filters, setFilters] = useState({ modality: "all", dep: "all" });
  const [mods, setMods] = useState([]);
  const [deps, setDeps] = useState([]);
  const [sums, setSums] = useState({ paied: 0, left: 0 });
  const [dateFilter, setDateFilter] = useState({
    from: moment().startOf("day").unix(),
    to: moment().endOf("day").unix()
  });
  const [onlyWithLeft, setOnlyWithLeft] = useState(false);
  const [readyReports, setReadyReports] = useState(false);
  const [searchPatResults, setSearchResultPat] = useState([]);
  const [selectedPat, setSelectePat] = useState({});
  const [wsObj, setWSObj] = useState(null);
  const [newVisitWS, setNewVisit] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const [openMoreOptinos, setMoreOptions] = useState(null);
  const [cloudStudies, setCloudStudies] = useState([]);

  const role = user?.role;
  const sumData = (arr) => {
    const sumObj = { paied: 0, left: 0 };
    for (let item of arr) {
      sumObj.paied += user?.isAdmin ? item.payment["resp"] : item.payment[role];
      sumObj.left += item.debit;
    }
    setSums(sumObj);
  };

  useEffect(() => {
    if (!newVisitWS) {
      return;
    }
    const temp = [...allVisits, newVisitWS];

    setAllVisits(temp);
  }, [newVisitWS]);

  useEffect(() => {
    if (!user) {
      return;
    }
    Promise.allSettled([
      api.get(`/get-modalities`),
      api.get(`/getDepartments`)
      // api.get(`/getCloudCases`)
    ]).then(([respMods, respDeps]) => {
      const modsData = respMods?.value?.data;
      const depsData = respDeps?.value?.data;
      // const cloudData = cloudStudies?.value?.data;
      setMods(modsData);
      setDeps(depsData);
      // setCloudStudies(cloudData);

      let selectedDep = "all";
      if (!user?.isAdmin) {
        selectedDep = user.department;
      }

      setFilters({ ...filters, dep: selectedDep });
    });
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (selectedPat?.idNum) {
      api.get(`/findPatVisits?id=${selectedPat?.idNum}`).then((resp) => {
        setAllVisits(resp.data);
        setFilteredVisits(resp.data);
      });
      return;
    }
    const start = dateFilter.from;
    const end = dateFilter.to;
    api
      .get(
        `/getAllVisits?from=${start}&to=${end}&user=${user._id}&allDebit=${onlyWithLeft}&readyReports=${readyReports}`
      )
      .then((resp) => {
        setAllVisits(resp.data);
        setFilteredVisits(resp.data);
      });
  }, [refresh, user, dateFilter, onlyWithLeft, selectedPat, readyReports]);

  useEffect(() => {
    if (!allVisits.length) {
      setFilteredVisits([]);
      sumData([]);
      return;
    }

    const filtered = allVisits.filter(
      (visit) =>
        (filters.modality === "all" ||
          filters.modality === visit?.reqExams[0]?.modality) &&
        (filters.dep === "all" ||
          filters.dep === visit?.department ||
          !visit.department)
    );
    sumData(filtered);
    setFilteredVisits(filtered);
  }, [allVisits, filters, mods, deps]);

  const closeDialog = () => {
    setRefresh(refresh + 1);
    setOpenNew(false);
  };

  const closeHistory = () => {
    setEditHistory(null);
  };

  const closePayment = () => {
    setRefresh(refresh + 1);
    setSelectedVisit(false);
  };

  const searchPat = (val) => {
    api.get(`/find-pat?id=${val}`).then((resp) => {
      setSearchResultPat(
        resp.data.map((p) => {
          return {
            _id: p._id.toString(),
            name: p.name,
            idNum: p.idNum,
            obj: p
          };
        })
      );
    });
  };

  const editVisit = (visit) => {
    setSelectedVisit(null);
    setVisitToUpdate(visit);
    setOpenNew(true);
  };

  // const uploadFile = (id, e) => {
  //   const file = e.target.files[0];
  //   let data = new FormData();
  //   data.append("file", file);
  //   data.append("fileName", `${id}[0].pdf`);
  //   data.append("id", id);
  //   api
  //     .post(`/attachDoc?id=${id}`, data, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .finally(() => {
  //       document.getElementById(`upload-button-${id}`).value = "";
  //     });
  // };

  const uploadFile = async (id, e) => {
    const file = e.target.files[0];
    const fileName = `${id}[0].pdf`;

    const response = await api.get(
      `/getPresignedUrl?fileName=${fileName}&fileType=${file.type}`
    );
    const { presignedUrl, url } = response.data;

    // Upload the file to S3
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type
      }
    });

    // Send the S3 URL of the uploaded file to your server
    await api.post(`/attachDoc`, { id, url, fileName });

    document.getElementById(`upload-button-${id}`).value = "";
  };

  const updateFilters = (field, value) => {
    if (field === "debit") {
      setOnlyWithLeft(value);
      if (value) {
        setReadyReports(false);
      }
      return;
    }

    setReadyReports(value);
    if (value) {
      setOnlyWithLeft(false);
    }
  };

  const updateStatus = (visitId, currentStatus, status) => {
    if (status === currentStatus) {
      return;
    }
    api
      .post(`/update-visit`, {
        id: visitId,
        data: { status }
      })
      .then(() => {
        setRefresh(refresh + 1);
      });
  };

  const openStudy = (id, onCloud = false) => {
    window.open(`${onCloud ? cloudUrl : localUrl}${id}`, "_blank");
  };

  const copyURL = (id, onCloud = false) => {
    navigator.clipboard.writeText(`${onCloud ? cloudUrl : localUrl}${id}`);
  };

  const exsistsInCloudArray = (id) => {
    return cloudStudies?.indexOf(id) > -1;
  };

  const isAdmin = user?.isAdmin;

  return (
    <div
      style={{
        padding: "20px",
        height: "calc(100% - 64px)",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {!user ? (
        <>
          <Typography variant="h2">No user! Please contact IT</Typography>
          <TextField
            valu={newToken}
            onChange={(e) => setToken(e.target.value)}
            label="token"
          />
          <Button
            onClick={() => {
              localStorage.setItem("u_id", newToken);
              window.location.reload();
            }}
          >
            add token
          </Button>
        </>
      ) : (
        <>
          <div className={classes.topSearch}>
            <Autocomplete
              style={{
                width: "60%"
              }}
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setSelectePat({});
                  return;
                }

                setSelectePat(newValue.obj);
                setOnlyWithLeft(false);
              }}
              selectOnFocus
              onInputChange={(e) => searchPat(e?.target?.value)}
              handleHomeEndKeys
              options={searchPatResults}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return `${option.name} - ${option.idNum}`;
              }}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Patient"
                  variant="outlined"
                />
              )}
            />
            <Button
              onClick={() => setOpenNew(!openNew)}
              variant="contained"
              color="primary"
              style={{
                height: "80%"
              }}
            >
              + New
            </Button>
          </div>
          <div className={classes.controls}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "25px"
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyWithLeft}
                    onChange={(e) => updateFilters("debit", e.target.checked)}
                  />
                }
                label={"Show only with left"}
                labelPlacement="end"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={readyReports}
                    onChange={(e) =>
                      updateFilters("reaportReady", e.target.checked)
                    }
                  />
                }
                label={"Show ready reports"}
                labelPlacement="end"
              />

              <IconButton onClick={() => setRefresh(refresh + 1)}>
                <Refresh />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <TextField
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                label="Search"
                variant="outlined"
                style={{ width: "100%" }}
              />

              {isAdmin && (
                <KeyboardDatePicker
                  clearable
                  value={moment.unix(dateFilter.from)}
                  placeholder="10/10/2018"
                  onChange={(newValue) =>
                    setDateFilter({
                      from: newValue.startOf("day").unix(),
                      to: newValue.endOf("day").unix()
                    })
                  }
                  format="DD/MM/YYYY"
                  variant="inline"
                  inputVariant="outlined"
                />
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }}
            >
              <RadioGroup
                value={filters?.modality}
                onChange={(event) =>
                  setFilters({ ...filters, modality: event.target.value })
                }
                className={classes.radioGroup}
                style={{
                  marginBottom: "10px"
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All Modalities"
                  labelPlacement="start"
                />
                {mods?.map((mod) => {
                  return (
                    <FormControlLabel
                      key={`${mod}-chkbox-option`}
                      control={<Radio />}
                      label={mod}
                      value={mod}
                      labelPlacement="start"
                    />
                  );
                })}
              </RadioGroup>

              <RadioGroup
                defaultValue="all"
                value={filters?.dep}
                onChange={(event) =>
                  setFilters({ ...filters, dep: event.target.value })
                }
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All Centers"
                  labelPlacement="start"
                  disabled={!isAdmin}
                />
                {deps?.map((dep) => {
                  return (
                    <FormControlLabel
                      key={`${dep}-chkbox-option`}
                      control={<Radio />}
                      label={dep}
                      value={dep}
                      labelPlacement="start"
                      disabled={!isAdmin && user?.department !== dep}
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>

          <div
            style={{ width: "50px", height: "20px", cursor: "pointer" }}
            onClick={() => setShowMoeny(!showMoney)}
          />
          <TableContainer component={Paper} style={{ flex: 1 }}>
            <Table sx={{ minWidth: 650 }} stickyHeader size="small">
              <TableHead>
                <TableRow
                  className={classes.tr}
                  style={{ backgroundColor: "#919191 !important" }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Exams</TableCell>
                  <TableCell>Dr</TableCell>
                  {(showMoney || isAdmin) && (
                    <>
                      <TableCell>Price</TableCell>
                      <TableCell>Left</TableCell>
                    </>
                  )}
                  <TableCell>Notes</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Center</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVisits
                  .filter((visit) =>
                    !searchVal?.length
                      ? true
                      : visit.patientId.idNum?.indexOf(searchVal) > -1 ||
                        visit.patientId.name
                          .toLowerCase()
                          ?.indexOf(
                            arabicRegix.test(searchVal)
                              ? searchVal
                              : searchVal.toLowerCase()
                          ) > -1 ||
                        visit.patientId.nameAr?.indexOf(
                          arabicRegix.test(searchVal)
                            ? searchVal
                            : searchVal.toLowerCase()
                        ) > -1
                  )
                  .map((visit, index) => {
                    const exams = [...visit?.reqExams];
                    if (visit.contrast) {
                      exams.push({ name: "with contrast" });
                    }
                    if (visit.sedation) {
                      exams.push({ name: "with sedation" });
                    }

                    const dicomIds = visit?.dicomIds || [];
                    const allCasesCloud =
                      dicomIds?.length && dicomIds.every(exsistsInCloudArray);
                    const someCasesCloud =
                      dicomIds?.length &&
                      !allCasesCloud &&
                      dicomIds.some(exsistsInCloudArray);
                    const allCasesLocal = dicomIds?.length && !someCasesCloud;

                    return (
                      <TableRow
                        key={visit._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 }
                        }}
                        onDoubleClick={() => setSelectedVisit(visit)}
                        className={classes.tr}
                        style={{
                          backgroundColor:
                            statusMap[visit?.status || "started"].color ||
                            "#fff"
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{visit.patientId.idNum}</TableCell>
                        <TableCell scope="row">
                          <Typography>{visit.patientId.name}</Typography>
                          <Typography>{visit.patientId.nameAr}</Typography>
                        </TableCell>
                        <TableCell>{`${exams
                          .map((ex) => ex.name)
                          ?.join(", ")} (${exams[0]?.modality})`}</TableCell>
                        <TableCell>{visit.refDoctor.nameEn}</TableCell>
                        {(showMoney || isAdmin) && (
                          <>
                            {isAdmin ? (
                              <TableCell>{visit.payment["resp"]}</TableCell>
                            ) : (
                              <TableCell>{visit.payment[role]}</TableCell>
                            )}
                            <TableCell
                              style={{
                                backgroundColor:
                                  visit.debit > 0 ? "#ea9899" : "unset"
                              }}
                            >
                              {visit.debit}
                            </TableCell>
                          </>
                        )}
                        <TableCell
                          style={{
                            backgroundColor: visit?.paymentType?.color || "#fff"
                          }}
                        >
                          <div>
                            {visit.paymentType.Name !== "Self" && (
                              <Typography>{`${visit.paymentType.Name} covered ${visit.insuranceCoverageAmount}`}</Typography>
                            )}
                            <Typography>{visit.notes}</Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          {moment.unix(visit.dateTime).isSame(moment(), "day")
                            ? moment.unix(visit.dateTime).format("HH:mm")
                            : moment
                                .unix(visit.dateTime)
                                .format("DD/MM/YYYY HH:mm")}
                        </TableCell>
                        <TableCell>{visit.department}</TableCell>
                        <TableCell>
                          {allCasesCloud
                            ? "Cloud"
                            : someCasesCloud
                            ? "some cloud"
                            : allCasesLocal
                            ? "local"
                            : "no Studies"}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <IconButton onClick={(e) => setMoreOptions(visit)}>
                              <MdOpenInFull />
                            </IconButton>
                            <IconButton
                              onClick={() => setEditHistory(visit._id)}
                            >
                              <img
                                alt={""}
                                src={getResourcePath("historyIcon.png")}
                                width="30"
                                height="30"
                              />
                            </IconButton>
                            <input
                              accept="application/pdf"
                              id={`upload-button-${visit._id}`}
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => uploadFile(visit._id, e)}
                            />
                            <label htmlFor={`upload-button-${visit._id}`}>
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                              >
                                <AttachFile
                                  style={{
                                    color:
                                      visit?.files?.length > 0 ? "#f00" : "#000"
                                  }}
                                />
                              </IconButton>
                            </label>
                            <IconButton
                              style={{
                                color: "#666",
                                visibility:
                                  visit?.files?.length > 0
                                    ? "visible"
                                    : "hidden"
                              }}
                              onClick={() => {
                                if (!visit?.files?.length) {
                                  return;
                                }
                                setSelectedVisitShow(visit);
                              }}
                            >
                              <Visibility />
                            </IconButton>
                            <IconButton
                              style={{
                                color: "blue",
                                visibility:
                                  visit.status === "read" ? "visible" : "hidden"
                              }}
                              onClick={() => {
                                if (visit.status !== "read") {
                                  return;
                                }
                                updateStatus(
                                  visit._id,
                                  visit.status,
                                  "reportSent"
                                );
                              }}
                            >
                              <Check />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow
                  className={classes.tr}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    backgroundColor: "#fffd59"
                  }}
                >
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  {!isAdmin && <TableCell />}
                  {(showMoney || isAdmin) && (
                    <>
                      <TableCell
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        {sums.paied}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold"
                        }}
                      >
                        {sums.left}
                      </TableCell>
                    </>
                  )}
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {openNew && (
            <AddEditVisit
              isOpen={openNew}
              closeDialog={closeDialog}
              editVisitObj={visitToUpdate}
              user={user}
            />
          )}
          {selectedVisit && (
            <PaymentEdit
              isOpen={selectedVisit}
              closeDialog={closePayment}
              visit={selectedVisit || {}}
              editVisit={editVisit}
              user={user}
            />
          )}
          {editHistory && (
            <EditHistory
              isOpen={!!editHistory}
              closeDialog={closeHistory}
              visitId={editHistory}
            />
          )}
          {selectedVisitShow && (
            <HistoryView
              isOpen={selectedVisitShow}
              closeDialog={() => setSelectedVisitShow(null)}
              visit={selectedVisitShow}
              user={user}
              updateList={() => {}}
            />
          )}

          {openMoreOptinos && (
            <Dialog open={true} onClose={() => setMoreOptions(null)}>
              <DialogTitle>{`Study actions for ${openMoreOptinos?.patientId?.name}`}</DialogTitle>
              <DialogContent>
                {openMoreOptinos?.dicomIds?.map((study) => {
                  return (
                    <div className={classes.moreActionsCont}>
                      <div className={classes.actionGroup}>
                        <Button
                          key={study}
                          onClick={() => openStudy(study)}
                          variant={"outlined"}
                        >
                          <FiServer />
                          view study local
                        </Button>
                        <IconButton onClick={() => copyURL(study)}>
                          <FiCopy />
                        </IconButton>
                      </div>
                      <div className={classes.actionGroup}>
                        {cloudStudies?.indexOf(study) > -1 && (
                          <Button
                            key={study}
                            onClick={() => openStudy(study, true)}
                            variant={"outlined"}
                          >
                            <TbCloudDataConnection /> view study cloud
                          </Button>
                        )}
                        {cloudStudies?.indexOf(study) > -1 && (
                          <IconButton onClick={() => copyURL(study, true)}>
                            <FiCopy />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setMoreOptions(null)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default Dashbaord;
