import React, { useState } from 'react';
import useStyles from "./style"
import api from "../../api";
import { Dialog, DialogContent, MenuItem, Select, Typography } from '@material-ui/core';
import moment from "moment"
import statusMap from "../../Services/statuses";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


const HistoryView = ({ isOpen, closeDialog, visit, updateList, user, setRefresh }) => {

    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const updateStatus = (event) => {
        const value = event.target.value;
        if (value === visit?.status) {
            return;
        }

        api.post(`/update-visit`, {
            id: visit?._id,
            data: { status: value }
        })

    }

    const age = moment().diff(visit?.patientId?.dob, 'years') < 1 ? `${moment().diff(visit?.patientId?.dob, 'months')} Months` : `${moment().diff(visit?.patientId?.dob, 'years')} Years`

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.dialogPaper }}
        >

            <DialogContent className={classes.container}>
                <div className={classes.historySection}>
                    <div className={classes.sectionName}>
                        {user?.isAdmin &&
                            <div>
                                <Select
                                    value={visit?.status || "started"}
                                    onChange={updateStatus}
                                >
                                    {
                                        Object.keys(statusMap)?.map((key) => (
                                            <MenuItem value={key} key={key}>{statusMap[key]?.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </div>}
                        <Typography>
                            Patient History
                        </Typography>
                        <Typography style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                            {`Done by: ${visit?.historyCollector?.initials || ""}`}
                        </Typography>
                    </div>

                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Name: </Typography>
                            <Typography>{`${visit?.patientId?.name}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Date of birth: </Typography>
                            <Typography>{`${moment(visit?.patientId?.dob).format('DD-MM-YYYY')} (${age})`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Weight: </Typography>
                            <Typography>{`${visit?.weight}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Gender: </Typography>
                            <Typography>{`${visit?.patientId?.gender || ""}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>ID: </Typography>
                            <Typography>{`${visit?.patientId?.idNum}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Payment Type: </Typography>
                            <Typography>{`${visit?.paymentType?.Name}`}</Typography>
                        </div>
                    </div>


                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Exam: </Typography>
                            <Typography>{`${visit?.reqExams.map((ex) => ex.name)} (${visit?.reqExams[0]?.modality})`}</Typography>
                        </div>
                    </div>

                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>History: </Typography>
                            <Typography>{`${visit?.genHistory}`}</Typography>
                        </div>
                    </div>

                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>History of Truamas: </Typography>
                            <div>

                                {
                                    visit?.trauma?.map((t, index) => {
                                        return (
                                            <Typography key={`treuma-${index}`}>{`${t}`}</Typography>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>History of Surgery: </Typography>
                            <div>
                                {
                                    visit?.surgery?.map((s, index) => {
                                        return (
                                            <Typography key={`surg-${index}`}>{`${s.summary} (Doctor: ${s.doctor})`}</Typography>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className={classes.dataHistory}>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Prior Exams: </Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Modality: </Typography>
                            <Typography>{`${visit?.priorExams?.modality || ""}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Media: </Typography>
                            <Typography>{`${visit?.priorExams?.media || ""}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Date: </Typography>
                            <Typography>{`${visit?.priorExams?.date || ""}`}</Typography>
                        </div>
                        <div className={classes.fieldCont}>
                            <Typography className={classes.fieldTitle}>Notes: </Typography>
                            <div>
                                {visit?.priorExams?.link && <a href={visit?.priorExams?.link || ""} target="_blank" rel="noreferrer">{`${visit?.priorExams?.link}`}</a>}
                                {visit?.priorExams?.notes && <Typography>{`${visit?.priorExams?.notes}`}</Typography>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.viewSection}>
                    <div className={classes.sectionName}>
                        <div />
                        <Typography>
                            Attachments
                        </Typography>
                        <div />
                    </div>
                    <div className={classes.pdfCont}>
                        {visit?.files.map((url) => {
                            return (
                                <Document key={url} file={url} onLoadSuccess={onDocumentLoadSuccess}>
                                    {Array.from({ length: numPages }, (_, i) => i + 1).map(page => (
                                        <Page pageNumber={page} />
                                    ))}
                                </Document>
                            )
                        })
                        }
                    </div>

                </div>

            </DialogContent>
        </Dialog >
    )
}

export default HistoryView;