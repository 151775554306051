import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '20px 40px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%'
    },
    patientSection: {
        display: 'flex',
        width: '100%',
        height: '40%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    patientInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        border: `1px solid #cecece`,
        borderRadius: '8px',
        padding: '5px 10px',
        backgroundColor: '#fbfbfb',
        boxSizing: 'border-box',
        minHeight: '40%',
        marginBottom: '20px'

    },
    patientField: {
        height: '50px',
        width: '80%',
        backgroundColor: '#fff'
    },
    fieldContainer: {
        display: 'flex',
        width: '40%',
        justifyContent: 'space-between',
        "& p": {
            width: '120px'
        }
    },
    fieldRoot: {
        backgroundColor: '#fff'
    },
    dialogPaper: {
        maxWidth: '90% !important',
        maxHeight: '90% !important',
        height: '90%',
        width: '90%'
    },
    sectionHeader: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    sectionRepeateContent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-around',
        flex: 1,
        overflowY: 'auto'
    },
    addBtn: {
        border: '2px solid #000',
        marginLeft: '20px'
    }
}));
export default useStyles;
