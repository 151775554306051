import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: "nowrap"
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        border: "1px solid #cecece",
        padding: '10px',
        borderRadius: '5px'
    },
    tr: {
        height: '10px',
        "& td": {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    mobileView: {
        "& td, tr": {
            fontSize: '11px !important'
        }
    },
    selectCont: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%"
    }
}));

export default useStyles;
