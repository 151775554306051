import { Box, FormControlLabel, IconButton, Paper, Radio, RadioGroup, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs, TextField, Typography } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import AddEditVisit from "../AddEditVisit/AddEditVisit";
import PaymentEdit from "../AddEditVisit/PaymentEdit";
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import useStyles from "./style";
import { TabContext } from "@material-ui/lab";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { YearRangePicker } from "react-year-range-picker";
import { modalitiesSorting } from "../../consts";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ height: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}



const SummaryPage = ({ user }) => {
    const classes = useStyles();
    const [type, setType] = useState(0);
    const [resp, setResp] = useState([]);
    const [visitsData, setVisitsData] = useState([]);
    const [rows, setRows] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [openNew, setOpenNew] = useState(false);
    const [showMoney, setShowMoeny] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [visitToUpdate, setVisitToUpdate] = useState(null);
    const [mods, setMods] = useState([]);
    const [filters, setFilters] = useState({ dep: "all", modality: 'all' });
    const [deps, setDeps] = useState([]);
    const [dateFilter, setDateFilter] = useState({ from: moment().startOf('month').unix(), to: moment().endOf('month').unix() });
    const [yearRange, setYearRange] = useState({ startYear: moment().format('YYYY'), endYear: moment().format('YYYY') });
    const [monthlyData, setMonthlyData] = useState([]);
    const [monthView, setMonthView] = useState("table");

    useEffect(() => {
        if (!user) {
            return;
        }
        Promise.allSettled([api.get(`/get-modalities`), api.get(`/getDepartments`)])
            .then(([respMods, respDeps]) => {
                const modsData = respMods?.value?.data;
                const depsData = respDeps?.value?.data;
                setMods(modsData);
                setDeps(depsData);
            })
    }, [user]);

    useEffect(() => {
        if (!user?.isAdmin) {
            return;
        }

        const start = dateFilter.from;
        const end = dateFilter.to;

        api.get(`/getMonthlyStats?startYear=${yearRange.startYear}&endYear=${yearRange.endYear}`)
            .then((resp) => {

                const temp = [...resp.data];

                for (let month of temp) {
                    for (let modKey of Object.keys(month.modalities)) {
                        const mod = month.modalities[modKey];
                        for (let depKey of Object.keys(mod.departments)) {
                            const depKeyStr = `${depKey}_total`;
                            const dep = mod.departments[depKey]
                            if (!([depKeyStr] in month)) {
                                month[depKeyStr] = 0;
                            }
                            month[depKeyStr] += dep
                        }
                    }
                }

                setMonthlyData(temp);

                return api.get(`/getAllVisits?from=${start}&to=${end}&user=${user._id}&allDebit=${false}`)
            })
            .then((res) => {
                setResp(res.data)
            });

    }, [refresh, user, dateFilter, yearRange]);

    useEffect(() => {

        const grouped = _.groupBy(resp, (a) => moment.unix(a.dateTime).format('DD-MM'))
        const totalData = {};
        const modsObj = {};
        for (let m of mods) {
            modsObj[m] = 0;
        }
        for (let day of Object.values(grouped)) {

            const dayData = day.reduce((obj, visit) => {
                if (filters.dep !== 'all' && filters.dep !== visit.department) {
                    return obj;
                }
                const payment = visit.payment.resp;
                obj[visit.reqExams[0].modality] = (obj[visit.reqExams[0].modality] || 0) + payment;
                return obj;
            }, { ...modsObj })

            totalData[day[0].dateTime] = dayData;
        }
        setVisitsData(totalData);
    }, [resp, mods, filters]);

    useEffect(() => {
        const tempRows = [];

        for (let day of Object.keys(visitsData)) {
            const obj = visitsData[day];

            tempRows.push(
                <TableRow
                    key={`${day}-key`}
                    className={classes.tr}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{
                        backgroundColor: '#f565e4',
                        "& *": {
                            fontWeight: 'bold !important',

                        }
                    }}
                >
                    <TableCell
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {moment.unix(day).format('dddd')}</TableCell>
                    <TableCell
                        style={{
                            fontWeight: 'bold'
                        }}
                    >{moment.unix(day).format('DD-MM-yyyy')}</TableCell>
                </TableRow>);
            tempRows.push(
                <TableRow style={{
                    backgroundColor: '#919191',
                    fontWeight: 'bold'
                }}
                    className={classes.tr}
                >
                    <TableCell
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Exam
                    </TableCell>
                    <TableCell
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        Total
                    </TableCell>
                </TableRow>
            )
            let total = 0;
            const dayRows = Object.keys(obj).map((type, index) => {
                const examTotal = obj[type];
                total += examTotal;

                return (
                    <TableRow
                        className={classes.tr}
                        key={`${day}-${type}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>
                            {type}
                        </TableCell>
                        <TableCell>
                            {examTotal}
                        </TableCell>
                    </TableRow>
                )
            })
            tempRows.push(...dayRows);
            tempRows.push(<TableRow
                className={classes.tr}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                style={{
                    backgroundColor: '#fffd59'
                }}
            >
                <TableCell
                    style={{
                        fontWeight: 'bold'
                    }}>
                    {`Total`}</TableCell>
                <TableCell
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {total}
                </TableCell>
            </TableRow>)

        }

        setRows(tempRows);
    }, [visitsData]);

    const closeDialog = () => {
        setRefresh(refresh + 1);
        setOpenNew(false);
    }

    const closePayment = () => {
        setRefresh(refresh + 1);
        setSelectedVisit(false);
    }

    const editVisit = (visit) => {
        setSelectedVisit(null);
        setVisitToUpdate(visit);
        setOpenNew(true);
    }

    return (

        <div style={{ padding: '20px', height: '100%', boxSizing: 'border-box', display: 'flex', flexDirection: 'column' }}>
            {
                user?.isAdmin &&
                <TabContext value={type}>
                    <Tabs value={type} centered onChange={(_, newVal) => setType(newVal)} variant="fullWidth" indicatorColor="secondary">
                        <Tab label="Monthly" {...a11yProps(0)} style={{ width: "50%" }} />
                        <Tab label="Daily" {...a11yProps(1)} style={{ width: "50%" }} />
                    </Tabs>
                    <>
                        <div className={classes.controls}>
                            {type === 1 ? <DatePicker
                                views={["year", "month"]}
                                openTo="month"
                                start
                                label={"Year and Month"}
                                value={moment.unix(dateFilter?.from)}
                                onChange={(val) => setDateFilter({ from: moment(val).startOf('month').unix(), to: moment(val).endOf('month').unix() })}
                            /> :
                                <div>
                                    <h2>Year range</h2>
                                    <YearRangePicker
                                        minYear={new Date().getFullYear() - 2}
                                        maxYear={new Date().getFullYear() + 2}
                                        onSelect={(startYear, endYear) => {
                                            setYearRange({ startYear, endYear })
                                        }}
                                        startYear={yearRange?.startYear}
                                        endYear={yearRange?.endYear}
                                    />
                                </div>
                            }

                            {(type === 1 || monthView === 'table') &&
                                <>
                                    <IconButton onClick={() => setRefresh(refresh + 1)}>
                                        <Refresh />
                                    </IconButton>

                                    <RadioGroup
                                        defaultValue="all"
                                        value={filters?.dep}
                                        onChange={(event) => setFilters({ ...filters, dep: event.target.value })}
                                        className={classes.radioGroup}
                                    >

                                        <FormControlLabel value="all" control={<Radio />} label="All Centers" labelPlacement="start" />
                                        {
                                            deps?.map((dep) => {
                                                return (
                                                    <FormControlLabel
                                                        key={`${dep}-chkbox-option`}
                                                        control={<Radio />}
                                                        label={dep}
                                                        value={dep}
                                                        labelPlacement="start"
                                                    />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </>
                            }

                            {(type === 0 && monthView === 'graphs') && <RadioGroup
                                value={filters?.modality}
                                onChange={(event) => setFilters({ ...filters, modality: event.target.value })}
                                className={classes.radioGroup}
                                style={{
                                    marginBottom: '10px'
                                }}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All Modalities" labelPlacement="start" />
                                {
                                    mods?.map((mod) => {
                                        return (
                                            <FormControlLabel
                                                key={`${mod}-chkbox-option`}
                                                control={<Radio />}
                                                label={mod}
                                                value={mod}
                                                labelPlacement="start"
                                            />
                                        )
                                    })
                                }
                            </RadioGroup>}
                        </div>

                        <div style={{ width: '50px', height: '20px', cursor: 'pointer' }} onClick={() => setShowMoeny(!showMoney)} />
                        <TabPanel value={type} index={1} className={classes.fullHeight}>
                            <TableContainer component={Paper} style={{ flex: 1 }}>
                                <Table sx={{ minWidth: 650 }} stickyHeader size="small">

                                    <TableBody>

                                        {
                                            rows.map((visitRow) => visitRow)
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={type} index={0} className={classes.fullHeight}>
                            <RadioGroup
                                defaultValue="table"
                                value={monthView}
                                onChange={(event) => setMonthView(event.target.value)}
                                className={classes.radioGroup}
                            >

                                <FormControlLabel value="table" control={<Radio />} label="Table view" labelPlacement="start" />
                                <FormControlLabel value="graphs" control={<Radio />} label="Graphs view" labelPlacement="start" />

                            </RadioGroup>
                            <div style={{ display: 'flex', flex: 1, flexDirection: "column", width: '100%', height: '100%' }}>
                                {monthView === 'table' ?
                                    (<div>
                                        {
                                            !!monthlyData?.length && monthlyData.map((month) => {

                                                const sortedModalities = Object.keys(month?.modalities).sort((a, b) => {
                                                    const aIndex = modalitiesSorting.findIndex(obj => obj.hasOwnProperty(a));
                                                    const bIndex = modalitiesSorting.findIndex(obj => obj.hasOwnProperty(b));
                                                    return aIndex - bIndex;
                                                })
                                                return (
                                                    <TableContainer component={Paper} key={`month-${month.month}-table`} style={{ marginBottom: '30px', flex: 1 }}>
                                                        <Table sx={{ minWidth: 650 }} stickyHeader size="small">
                                                            <TableBody>
                                                                <TableRow style={{ backgroundColor: "rgb(245, 101, 228)" }}>
                                                                    <TableCell>
                                                                        Month
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {moment(month.month).format("MMMM-YYYY")}
                                                                    </TableCell>
                                                                </TableRow>
                                                                {
                                                                    sortedModalities?.map((modKey) => {
                                                                        const mod = month.modalities[modKey];

                                                                        return Object.keys(mod?.departments)?.map((depKey) => {
                                                                            const dep = mod.departments[depKey];

                                                                            if (filters?.dep !== 'all' && filters?.dep !== depKey) {
                                                                                return null;
                                                                            }
                                                                            return (
                                                                                <TableRow key={`${modKey}-${depKey}`}>
                                                                                    <TableCell>
                                                                                        {`${depKey} - ${modKey}`}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {dep || 0}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })
                                                                    })
                                                                }
                                                                <TableRow style={{ backgroundColor: "rgb(255, 253, 89)" }}>
                                                                    <TableCell>
                                                                        Totals
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {(filters?.dep !== 'all' ? (month[`${filters?.dep}_total`] || 0) : month?.total) || 0}
                                                                    </TableCell>
                                                                </TableRow>

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>)
                                            })
                                        }
                                    </div>) :
                                    <>
                                        {
                                            !!monthlyData?.length && deps.map((dep) => {
                                                return (
                                                    <>
                                                        <Typography variant="h5">{dep}</Typography>
                                                        <ResponsiveContainer width="100%" height="25%">
                                                            <BarChart
                                                                syncId={'a1'}
                                                                width={500}
                                                                height={300}
                                                                data={monthlyData}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis dataKey="month" tickFormatter={(val) => {
                                                                    return moment(val).format("MMMM-YYYY");
                                                                }} />
                                                                <YAxis tickFormatter={(val) => val.toLocaleString()} />
                                                                <Tooltip
                                                                    labelFormatter={(val) => `${dep}\n${moment(val).format("MMMM-YYYY")}`}
                                                                    formatter={(val) => val.toLocaleString()}
                                                                />
                                                                <Legend />
                                                                {(filters?.modality === 'all' || filters?.modality === "MRI") &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="MRI"
                                                                        dataKey={(val) => {
                                                                            return (val?.modalities && val?.modalities['MRI'] && val?.modalities['MRI'].departments[dep]) || 0
                                                                        }}
                                                                        fill="#8884d8"
                                                                    />}
                                                                {(filters?.modality === 'all' || filters?.modality === "CT") &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="CT"
                                                                        dataKey={(val) => {
                                                                            return (val?.modalities && val?.modalities['CT'] && val?.modalities['CT'].departments[dep]) || 0
                                                                        }}
                                                                        fill="#f00"
                                                                    />}
                                                                {(filters?.modality === 'all' || filters?.modality === "US") &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="US"
                                                                        dataKey={(val) => {
                                                                            return (val?.modalities && val?.modalities['US'] && val?.modalities['US'].departments[dep]) || 0
                                                                        }}
                                                                        fill="#027a0e"
                                                                    />}
                                                                {(filters?.modality === 'all' || filters?.modality === "XRAY") &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="XRAY"
                                                                        dataKey={(val) => {
                                                                            return (val?.modalities && val?.modalities['XRAY'] && val?.modalities['XRAY'].departments[dep]) || 0
                                                                        }}
                                                                        fill="#00f"
                                                                    />}
                                                                {(filters?.modality === 'all' || filters?.modality === "Outpatient") &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="Outpatient"
                                                                        dataKey={(val) => {
                                                                            return (val?.modalities && val?.modalities['Outpatient'] && val?.modalities['Outpatient'].departments[dep]) || 0
                                                                        }}
                                                                        fill="#686868"
                                                                    />}

                                                                {(filters?.modality === 'all') &&
                                                                    <Bar
                                                                        barSize={20}
                                                                        name="MonthTotal"
                                                                        dataKey={(val) => {
                                                                            return val[`${dep}_total`] || 0
                                                                        }}
                                                                        fill="#000"
                                                                    />}
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                        <div style={{ width: '100%', height: '50px' }} />
                                                    </>
                                                )
                                            })
                                        }
                                        <Typography variant="h5">{"Totals"}</Typography>
                                        <ResponsiveContainer width="100%" height="25%">
                                            <BarChart
                                                syncId={'a1'}
                                                width={500}
                                                height={300}
                                                data={monthlyData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="month" tickFormatter={(val) => {
                                                    return moment(val).format("MMMM-YYYY");
                                                }} />
                                                <Tooltip
                                                    labelFormatter={(val) => `Total ${moment(val).format("MMMM-YYYY")}`}
                                                    formatter={(val) => val.toLocaleString()}

                                                />
                                                <Legend />
                                                <YAxis tickFormatter={(val) => val.toLocaleString()} />
                                                {(filters?.modality === 'all' || filters?.modality === "MRI") &&
                                                    <Bar
                                                        barSize={20}
                                                        name="MRI"
                                                        dataKey={(val) => {
                                                            return (val?.modalities && val?.modalities['MRI'] && val?.modalities['MRI'].modalityTotal) || 0
                                                        }}
                                                        fill="#8884d8"
                                                    />}
                                                {(filters?.modality === 'all' || filters?.modality === "CT") &&
                                                    <Bar
                                                        barSize={20}
                                                        name="CT"
                                                        dataKey={(val) => {
                                                            return (val?.modalities && val?.modalities['CT'] && val?.modalities['CT'].modalityTotal) || 0
                                                        }}
                                                        fill="#f00"
                                                    />}
                                                {(filters?.modality === 'all' || filters?.modality === "US") &&
                                                    <Bar
                                                        barSize={20}
                                                        name="US"
                                                        dataKey={(val) => {
                                                            return (val?.modalities && val?.modalities['US'] && val?.modalities['US'].modalityTotal) || 0
                                                        }}
                                                        fill="#027a0e"
                                                    />}
                                                {(filters?.modality === 'all' || filters?.modality === "XRAY") &&
                                                    <Bar
                                                        barSize={20}
                                                        name="XRAY"
                                                        dataKey={(val) => {
                                                            return (val?.modalities && val?.modalities['XRAY'] && val?.modalities['XRAY'].modalityTotal) || 0
                                                        }}
                                                        fill="#00f"
                                                    />}
                                                {(filters?.modality === 'all' || filters?.modality === "Outpatient") &&
                                                    <Bar
                                                        barSize={20}
                                                        name="Outpatient"
                                                        dataKey={(val) => {
                                                            return (val?.modalities && val?.modalities['Outpatient'] && val?.modalities['Outpatient'].modalityTotal) || 0
                                                        }}
                                                        fill="#686868"
                                                    />}
                                                {(filters?.modality === 'all') &&
                                                    <Bar
                                                        barSize={20}
                                                        name="Total"
                                                        dataKey={(val) => {
                                                            return deps.reduce((total, dep) => {
                                                                total += val[`${dep}_total`]
                                                                return total
                                                            }, 0)
                                                        }}
                                                        fill="#000"
                                                    />}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </>}
                            </div>

                        </TabPanel>
                        {openNew && <AddEditVisit
                            isOpen={openNew}
                            closeDialog={closeDialog}
                            editVisitObj={visitToUpdate}
                            user={user}
                        />}
                        {selectedVisit && <PaymentEdit
                            isOpen={selectedVisit}
                            closeDialog={closePayment}
                            visit={selectedVisit || {}}
                            editVisit={editVisit}
                            user={user}
                        />}
                    </>


                </TabContext>
            }
        </div >


    )
}

export default SummaryPage;